<template>
    <div>
        <BRow class="justify-content-center" align-v="center">
            <div class="col-lg-6 col-xl-4 col-12">
                <div class="image-container">
                    <BImg
                        class="image"
                        src="https://banedev.s3.us-east-2.amazonaws.com/Portfolio/DSC01314.jpg"
                    />
                </div>
            </div>

            <div class="col-lg-6 col-12">
                <h1 class="text-center">My Story</h1>

                <p>
                    My introduction to writing code came much later than I realized it did for most. It was on my first day of college when 
                    I watched as classmates already knew the answers to questions, while I was still figuring out what a variable was. I had 
                    entered the engineering program at Iowa State, knowing that engineering was the best way my mind worked. I enjoy getting 
                    a problem, putting together a solution, and seeing the outcome.
                </p>

                <p>
                    I chose Software Engineering as my focus because it seemed like the best option to feed my need to create. The options 
                    sounded endless as I read through yet another Google search of different engineering fields. It turns out Google was correct, 
                    and my childhood dream of becoming an "inventor" came true, in a way.
                </p>

                <p>
                    This is where I think my engineering degree shifted into my passion for web development. In a sense, you're always inventing 
                    a new screen, component, API request, or database schema. The excitement of seeing a component work as expected, some styling 
                    come out beautifully (after several meticulous corrections), or seeing your data relations weave together seamlessly never gets old.
                </p>

                <p>
                    To add to the endless amount of creativity, I love working with others. Web development would be impossible without collaboration. 
                    Even the tools used and packages imported act as a testament to this. I've always enjoyed bouncing ideas off of teammates and learning 
                    from their skill sets. I think it is essential to improve others as much as they have improved me. Much can be learned from having an 
                    open mind and a willingness to try something new.
                </p>

                <p>
                    I'm always striving for a new challenge. That could mean adopting a new responsibility, working with a language I am uncomfortable with, 
                    or convincing myself that a complicated task will unravel if I just take one step and then another. I hope that my career will reflect 
                    that ambition and show that I never stopped learning.
                </p>

                <p>
                    To find out some more fun facts about me, click on the icons below. If you would rather just get straight to business, there is a 
                    button that will download a copy of my resume — it is yours to keep.
                </p>
            </div>
        </BRow>

        <BRow class="mt-md-5 mt-2">
            <BCol cols="4" class="text-right">
                <FontAwesomeIcon
                    :icon="[ 'fas', 'camera-retro' ]"
                    class="fa-4x mr-2"
                    v-b-popover.hover.top="'Photography'"
                    @click="toggleCollapse('cam')"
                />
            </BCol>

            <BCol cols="4" class="text-center">
                <FontAwesomeIcon
                    :icon="[ 'fas', 'seedling' ]"
                    class="fa-4x mr-2"
                    v-b-popover.hover.top="'Plants'"
                    @click="toggleCollapse('seed')"
                />
            </BCol>

            <BCol cols="4" class="text-left">
                <FontAwesomeIcon
                    :icon="[ 'fas', 'dumbbell' ]"
                    class="fa-4x mr-2"
                    v-b-popover.hover.top="'Fitness'"
                    @click="toggleCollapse('gym')"
                />
            </BCol>
        </BRow>

        <BRow class="justify-content-center">
            <div class="col-lg-6 col-12">
                <BCollapse
                    id="collapse-info"
                    class="text-center"
                    :visible="visible"
                >
                    <BCard>
                        {{ funFacts[selection] }}

                        <div 
                            v-if="selection === 'cam'"
                            class="mt-2"
                        >
                            <a 
                                class="text-decoration-none"
                                href="https://www.photo.davidbane.com"
                                target="_blank"
                            >
                                View the photography portfolio
                            </a>
                        </div>
                    </BCard>
                </BCollapse>
            </div>
        </BRow>

        <BRow class="justify-content-center mt-md-5 mt-4 mb-5">
            <a
                href="https://banedev.s3.us-east-2.amazonaws.com/Portfolio/DavidBaneResume2023.pdf"
                download="DavidBaneResume2023.pdf"
                target="_blank"
                v-b-popover.hover.bottom="'Download'"
            >
                <BButton
                    variant="primary"
                >
                    Download my Resume
                </BButton>
            </a>
        </BRow>
    </div>
</template>

<script>
    import Vue from 'vue';

    import {library} from "@fortawesome/fontawesome-svg-core";
    import {
        faCameraRetro,
        faDumbbell,
        faSeedling,

    } from '@fortawesome/free-solid-svg-icons';

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faCameraRetro,
        faDumbbell,
        faSeedling,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    export default {
        name: 'About',
        data() {
            return {
                visible: false,
                selection: '',
                funFacts: {
                    cam: 'Photography is my other creative outlet.  I love being able to look at the world in the form of a composition.  ' +
                        'To wonder, "how can I take this everyday sight and turn it into something beautiful"? In turn, creating a ' +
                        'moment frozen in time thanks to a very expensive piece of plastic and glass held in my hands.',
                    seed: 'To cut straight to the point, I have a lot of plants - 25 at the time of me writing this.  I\'m not really ' +
                        'sure how it happened.  One day I had a single plant, then 5 and now my living room is a mini jungle.  It\'s ' +
                        'exciting to watch them grow and learn what each needs to live.  Plus, have you ever been in a plant filled room' +
                        ' and felt tense or uneasy?',
                    gym: 'I have always been active throughout my life, but I truly found a passion for weightlifting in college. I needed ' + 
                        'something to replace the sports that I had played throughout highschool. It also turns out that picking up weights was ' +
                        'a fantastic stress reliever, coursework had a knack for supplying it.  I can count endless times where I left the gym ' + 
                        'feeling collected and ready to seize the rest of the day. Since then, my fitness ' + 
                        'journey has continued to evolve and would fill the role of one of my biggest priorities.'
                }
            }
        },
        methods: {
            toggleCollapse(selection) {
                if (!this.visible) {
                    this.selection = selection;
                    this.visible = true;
                } else if (this.visible && selection !== this.selection) {
                    this.selection = selection;
                } else {
                    this.visible = false;
                }
            }
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"

    .collapse
        .card
            border: none !important
            background-color: #F5F5F5 !important

    .fa-4x
        color: $primary-green
        &:hover
            cursor: pointer
            color: $secondary-green

    @media (min-width: 768px)
        .image-container
            width: 400px
            margin: auto

            .image
                max-height: 100%
                max-width: 100%
                border-radius: 20px

    .image-container
        width: 350px
        margin: auto

        .image
            max-height: 100%
            max-width: 100%
            border-radius: 20px

    h1
        color: $primary-green
</style>
